import { fetchIntercept, handleResponse } from '../helpers';

function read() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/configuration/read', requestOptions).then(handleResponse);
}

function save(config) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({config})
  };

  return fetchIntercept('/api/configuration/save', requestOptions).then(handleResponse);
}

export const configurationService = {
  read,
  save
};
