import { fetchIntercept, handleResponse } from '../helpers';

function list() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/staging', requestOptions).then(handleResponse);
}

function patch(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({data})
  };

  return fetchIntercept('/api/staging/patch', requestOptions).then(handleResponse);
}

function remove(selected) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({selected})
  };

  return fetchIntercept('/api/staging/remove', requestOptions).then(handleResponse);
}

function update(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetchIntercept('/api/staging/update', requestOptions).then(handleResponse);
}

export const stagingService = {
  list,
  patch,
  remove,
  update,
};
