import * as types from '../constants/ActionTypes';
import { userService } from '../services';
import { history } from '../helpers';
import {alertActions} from './alertActions';

function loginRequest(user) {
  return {
    type: types.USERS_LOGIN_REQUEST,
    user
  };
}
function loginSuccess(user) {
  return {
    type: types.USERS_LOGIN_SUCCESS,
    payload: user,
  };
}
function loginFailure(error) {
  return {
    type: types.USERS_LOGIN_FAILURE,
    error
  };
}

function register(user) {
  function request(user) { return { type: types.USERS_REGISTER_REQUEST, user }; }
  function success(user) { return { type: types.USERS_REGISTER_SUCCESS, user }; }
  function failure(error) { return { type: types.USERS_REGISTER_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request(user));

    userService.register(user)
      .then(() => {
        dispatch(success());
        history.push('/login');
        dispatch(alertActions.success('Registration successful'));
      })
      .catch((err) => {
        dispatch(failure(err.message));
        dispatch(alertActions.error(err.toString()));
      });
  };
}

function logout() {
  function request() { return { type: types.USERS_LOGOUT_REQUEST }; }
  function success() { return { type: types.USERS_LOGOUT_SUCCESS }; }
  function failure(error) { return { type: types.USERS_LOGOUT_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());

    userService.logout()
      .then(() => {
        dispatch(success());
        history.push('/login');
      })
      .catch((e) => {
        dispatch(failure(e.message));
        history.push('/login');
      });
  };
}

// function logout() {
//   userService.logout();
//   return { type: types.USERS_LOGOUT };
// }

export const userActions = {
  loginRequest,
  loginSuccess,
  loginFailure,
  register,
  logout,
};
