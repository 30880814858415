import { handleResponse, fetchIntercept } from '../helpers';

function list() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/ad-tpl', requestOptions).then(handleResponse);
}

function create(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetchIntercept('/api/ad-tpl', requestOptions).then(handleResponse);
}

function remove(tpl) {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept(`/api/ad-tpl/${tpl}`, requestOptions).then(handleResponse);
}

export const autoDeploymentTplService = {
  list,
  create,
  remove,
};
