import { handleResponse, fetchIntercept } from '../helpers';

function getConfigs() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/client/config', requestOptions).then(handleResponse);
}

function setConfigs(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetchIntercept('/api/client/config', requestOptions).then(handleResponse);
}

function macOsUpdatelist() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/client/macos-update', requestOptions).then(handleResponse);
}

function linuxUpdateList() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/client/linux-update', requestOptions).then(handleResponse);
}

function macOsUpdateHosts(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetchIntercept('/api/client/macos-update/hosts', requestOptions).then(handleResponse);
}

function linuxUpdateHosts(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetchIntercept('/api/client/linux-update/hosts', requestOptions).then(handleResponse);
}

export const clientService = {
  getConfigs,
  setConfigs,
  macOsUpdatelist,
  macOsUpdateHosts,
  linuxUpdateList,
  linuxUpdateHosts,
};
