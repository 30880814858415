import { handleResponse, fetchIntercept } from '../helpers';

function baseline() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/compliance/baseline', requestOptions).then(handleResponse);
}

function list() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/compliance', requestOptions).then(handleResponse);
}

function create(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetchIntercept('/api/compliance', requestOptions).then(handleResponse);
}

function remove(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetchIntercept('/api/compliance/remove', requestOptions).then(handleResponse);
}

function disable(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetchIntercept('/api/compliance/disable', requestOptions).then(handleResponse);
}

export const complianceService = {
  baseline,
  list,
  create,
  remove,
  disable,
};
