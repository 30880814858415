import { fetchIntercept, handleResponse } from '../helpers';

function info() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/system/info', requestOptions).then(handleResponse);
}

export const systemService = {
  info,
};
