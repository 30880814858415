import { handleResponse, fetchIntercept } from '../helpers';

function list() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/app-waiver', requestOptions).then(handleResponse);
}

function remove(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ids: data})
  };

  return fetchIntercept('/api/app-waiver/remove', requestOptions).then(handleResponse);
}

export const appWaiverService = {
  list,
  remove,
};
