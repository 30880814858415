import { fetchIntercept, handleResponse } from '../helpers';

function products(osFilter) {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept(`/api/patch/products?os=${osFilter}`, requestOptions).then(handleResponse);
}

function status(mixed = true) {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept(`/api/sccm/packages-status${mixed ? '?mixed=1' : '?mixed=0'}`, requestOptions).then(handleResponse);
}

function patch(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetchIntercept('/api/sccm/patch', requestOptions).then(handleResponse);
}

function patchMacOs(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetchIntercept('/api/action/macos-patch', requestOptions).then(handleResponse);
}

function patchNoDeploy(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetchIntercept('/api/sccm/patch-no-deploy', requestOptions).then(handleResponse);
}

function patchRemote(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetchIntercept('/api/sccm/copy-package', requestOptions).then(handleResponse);
}

function downloadPackage(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetchIntercept('/api/sccm/download-package', requestOptions).then(handleResponse);
}

function uninstall(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };

  return fetchIntercept('/api/sccm/uninstall', requestOptions).then(handleResponse);
}

function uninstallPackage(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetchIntercept('/api/sccm/uninstall/package', requestOptions).then(handleResponse);
}

function uninstallMacOs(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetchIntercept('/api/action/macos-uninstall', requestOptions).then(handleResponse);
}

function patchBulk(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetchIntercept('/api/sccm/patch-bulk', requestOptions).then(handleResponse);
}

function patchMacOsBulk(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetchIntercept('/api/action/macos-patch-bulk', requestOptions).then(handleResponse);
}

function patchCustom(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetchIntercept('/api/sccm/patch-custom', requestOptions).then(handleResponse);
}

function patchUnauthorized(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetchIntercept('/api/sccm/patch-unauthorized', requestOptions).then(handleResponse);
}

function patchCustomDownload(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetchIntercept('/api/sccm/patch-custom-download', requestOptions).then(handleResponse);
}

function patchZombie(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetchIntercept('/api/sccm/patch-zombie', requestOptions).then(handleResponse);
}

function patchDriver(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetchIntercept('/api/driver/patch', requestOptions).then(handleResponse);
}

function patchHostsDriver(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetchIntercept('/api/sccm/patch-hosts-driver', requestOptions).then(handleResponse);
}

function hosts(vendor, product, patchVersion, uninstall = false, osFilter = null) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      vendor, product, patch_version: patchVersion, uninstall
    })
  };

  return fetchIntercept(`/api/patch/hosts${osFilter ? `?os=${osFilter}` : ''}`, requestOptions).then(handleResponse);
}

export const patchService = {
  products,
  status,
  patch,
  patchNoDeploy,
  patchRemote,
  downloadPackage,
  patchBulk,
  patchMacOsBulk,
  uninstall,
  uninstallPackage,
  patchCustom,
  patchUnauthorized,
  patchCustomDownload,
  patchZombie,
  patchDriver,
  patchHostsDriver,
  hosts,
  patchMacOs,
  uninstallMacOs,
};
