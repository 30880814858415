import { fetchIntercept, handleDownload, handleResponse } from '../helpers';

function products() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/undetected-product/list', requestOptions).then(handleResponse);
}

function productsDownload(search) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({search})
  };

  return fetchIntercept('/api/undetected-product/list', requestOptions).then(handleDownload);
}

function hosts(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetchIntercept('/api/undetected-product/hosts', requestOptions).then(handleResponse);
}

function hostsDownload(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetchIntercept('/api/undetected-product/hosts-download', requestOptions).then(handleDownload);
}

export const undetectedProductService = {
  products,
  productsDownload,
  hostsDownload,
  hosts,
};
