import * as types from '../constants/ActionTypes';
import {systemService} from '../services';

function info() {

  function request() { return { type: types.SYSTEM_INFO_REQUEST }; }
  function success(data) { return { type: types.SYSTEM_INFO_SUCCESS, data }; }
  function failure(error) { return { type: types.SYSTEM_INFO_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());

    systemService.info()
      .then((status) => {
        if (status.error) throw new Error(status.error);
        dispatch(success(status.data));
      })
      .catch((e) => {
        dispatch(failure(e.message));
      });
  };

}

export const systemActions = {
  info,
};
