import {
  USERS_REGISTER_REQUEST,
  USERS_REGISTER_SUCCESS,
  USERS_REGISTER_FAILURE,
} from '../constants/ActionTypes';

const INITIAL_STATE = {};

const registration = (state = INITIAL_STATE, action = null) => {
  switch (action.type) {
    case USERS_REGISTER_REQUEST:
      return {
        ...state,
        registering: true
      };
    case USERS_REGISTER_SUCCESS:
      return {
        ...state
      };
    case USERS_REGISTER_FAILURE:
      return {
        ...state
      };
    default:
      return state;
  }
};

export default registration;
