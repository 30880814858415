import { fetchIntercept, handleResponse } from '../helpers';

function alerts() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/schedule-alert', requestOptions).then(handleResponse);
}

function create(scheduleData) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(scheduleData),
  };

  return fetchIntercept('/api/schedule-alert', requestOptions).then(handleResponse);
}

function remove(ids) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ ids }),
  };

  return fetchIntercept('/api/schedule-alert/delete', requestOptions).then(handleResponse);
}

export const alertService = {
  alerts,
  create,
  remove,
};
