import { handleDownload, handleResponse, fetchIntercept } from '../helpers';

function list() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/article', requestOptions).then(handleResponse);
}

function hosts(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetchIntercept('/api/article/hosts', requestOptions).then(handleResponse);
}

function hostsPdfDownload(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetchIntercept('/api/article/pdf/hosts', requestOptions).then(handleDownload);
}

function csvPdfDownload(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetchIntercept('/api/article/pdf/cve', requestOptions).then(handleDownload);
}

function deploy(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetchIntercept('/api/article/deploy', requestOptions).then(handleResponse);
}

function bulkDeploy(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetchIntercept('/api/article/deploy-bulk', requestOptions).then(handleResponse);
}

function getAutoDeploymentSettings() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/article/wsu', requestOptions).then(handleResponse);
}

function setAutoDeploymentSettings(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };

  return fetchIntercept('/api/article/wsu', requestOptions).then(handleResponse);
}

export const articleService = {
  list,
  hosts,
  hostsPdfDownload,
  csvPdfDownload,
  deploy,
  bulkDeploy,
  getAutoDeploymentSettings,
  setAutoDeploymentSettings,
};
