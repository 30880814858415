import { fetchIntercept, handleResponse } from '../helpers';

function newestCves() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/cve/list', requestOptions).then(handleResponse);
}

function cve(cveId) {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept(`/api/cve/${cveId}`, requestOptions).then(handleResponse);
}

function consequence() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/cve/consequence', requestOptions).then(handleResponse);
}

export const cveService = {
  newestCves,
  cve,
  consequence,
};
