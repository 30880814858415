import { handleDownload, fetchIntercept } from '../helpers';

function downloadAgentUi() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/agent/ui-download', requestOptions).then(handleDownload);
}

function downloadPlugin() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/sccm/download', requestOptions).then(handleDownload);
}

export const downloadService = {
  downloadAgentUi,
  downloadPlugin,
};
