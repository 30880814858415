import * as types from '../constants/ActionTypes';

function success(message) {
  return { type: types.ALERT_SUCCESS, alertMessage: message };
}

function error(message) {
  return { type: types.ALERT_ERROR, alertMessage: message };
}

function clear() {
  return { type: types.ALERT_CLEAR };
}

export const alertActions = {
  success,
  error,
  clear
};
