import { fetchIntercept, handleResponse } from '../helpers';

function hosts(os = []) {
  const requestOptions = {
    method: 'GET',
  };

  const query = os.map((item) => `os=${item}`).join('&');

  return fetchIntercept(`/api/host/list${query ? `?${query}` : ''}`, requestOptions).then(handleResponse);
}

function hostsClients(os = []) {
  const requestOptions = {
    method: 'GET',
  };

  const query = os.map((item) => `os=${item}`).join('&');

  return fetchIntercept(`/api/host/clients${query ? `?${query}` : ''}`, requestOptions).then(handleResponse);
}

function hostAllProducts(hostId) {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept(`/api/host/${hostId}`, requestOptions).then(handleResponse);
}

function hostArticles(hostId) {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept(`/api/host/articles/${hostId}`, requestOptions).then(handleResponse);
}

function upgradableHosts() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/host/upgradable/', requestOptions).then(handleResponse);
}

function isHostUpgradable(hostId) {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept(`/api/host/upgradable/${hostId}`, requestOptions).then(handleResponse);
}

function memberProducts(hostName) {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept(`/api/host/name/${hostName}`, requestOptions).then(handleResponse);
}

function hostsDelete(ids) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ product_ids: ids })
  };

  return fetchIntercept('/api/host/delete', requestOptions).then(handleResponse);
}

function modelManufacturer() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/host/model-manufacturer', requestOptions).then(handleResponse);
}

function hostsByModelManufacturer(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetchIntercept('/api/host/model-manufacturer', requestOptions).then(handleResponse);
}

function getVMInfo(id) {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept(`/api/host/vm/${id}`, requestOptions).then(handleResponse);
}

export const hostService = {
  hosts,
  hostsClients,
  hostAllProducts,
  hostArticles,
  upgradableHosts,
  isHostUpgradable,
  hostsDelete,
  memberProducts,
  modelManufacturer,
  hostsByModelManufacturer,
  getVMInfo,
};
