import { fetchIntercept, handleResponse } from '../helpers';

function options() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/non-compliant', requestOptions).then(handleResponse);
}

function save(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetchIntercept('/api/non-compliant', requestOptions).then(handleResponse);
}

function disable() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/non-compliant/disable', requestOptions).then(handleResponse);
}

export const nonCompliantService = {
  options,
  save,
  disable,
};
