function formatType(val) {
  return typeof val === 'string' ? val.toLowerCase() : val;
}

export const sortData = (data, orderBy, order) => {
  const n = [];
  const s = [];

  data.forEach((item) => {
    if (typeof item[orderBy] === 'number' || !isNaN(item[orderBy])) {
      n.push(item);
    } else {
      s.push(item);
    }
  });

  let result;

  if (order === 'desc') {
    const nSorted = n.sort((a, b) => (b[orderBy] * 1 < a[orderBy] * 1 ? -1 : 1));
    const sSorted = s.sort((a, b) => (formatType(b[orderBy]) < formatType(a[orderBy]) ? -1 : 1));
    result = [...sSorted, ...nSorted];
  } else {
    const nSorted = n.sort((a, b) => (a[orderBy] * 1 < b[orderBy] * 1 ? -1 : 1));
    const sSorted = s.sort((a, b) => (formatType(a[orderBy]) < formatType(b[orderBy]) ? -1 : 1));
    result = [...nSorted, ...sSorted];
  }

  return result;
};
