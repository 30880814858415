import { handleResponse, fetchIntercept } from '../helpers';

function scan(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetchIntercept('/api/action/scan', requestOptions).then(handleResponse);
}

function macOsUpdate(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetchIntercept('/api/action/macos-update', requestOptions);
}

function linuxUpdate(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetchIntercept('/api/action/linux-update', requestOptions).then(handleResponse);
}

function status(action) {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept(`/api/action/status?action=${action}`, requestOptions).then(handleResponse);
}

function statusInformation(id) {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept(`/api/action/status/${id}`, requestOptions).then(handleResponse);
}

function windowsUpgrade(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({clients: data}),
  };

  return fetchIntercept('/api/action/windows-upgrade', requestOptions).then(handleResponse);
}

function linuxCustomPatch(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };

  return fetchIntercept('/api/action/linux-patch-custom', requestOptions).then(handleResponse);
}

function redeploy(id) {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept(`/api/action/redeploy/${id}`, requestOptions).then(handleResponse);
}

function remove(id) {
  const requestOptions = {
    method: 'DELETE',
  };

  return fetchIntercept(`/api/action/${id}`, requestOptions).then(handleResponse);
}

export const actionService = {
  scan,
  macOsUpdate,
  status,
  statusInformation,
  linuxUpdate,
  windowsUpgrade,
  linuxCustomPatch,
  redeploy,
  remove,
};
