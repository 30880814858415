import {colors, alpha} from '@material-ui/core';
import React from 'react';

const {
  cyan,
  green,
  grey,
} = colors;

const cyan500 = cyan[500];
const cyan700 = cyan[700];
const green400 = green[400];
const grey50 = grey[50];
const grey100 = grey[100];
const grey200 = grey[200];
const grey300 = grey[300];
const grey400 = grey[400];
const grey500 = grey[500];
const white = '#ffffff';
const darkBlack = 'rgba(0, 0, 0, 0.87)';
const fullBlack = 'rgba(0, 0, 0, 1)';

/**
 *  Light Theme is the default theme used in material-ui. It is guaranteed to
 *  have all theme variables needed for every component. Variables not defined
 *  in a custom theme will default to these values.
 */
export default {
  spacing: 8,
  typography: {
    fontFamily: 'Calibri',
    fontSize: 16,
    useNextVariants: true,
  },
  borderRadius: 0.9,
  palette: {
    primary: {
      light: '#026774',
      main: '#025360',
      dark: '#023f4c',
      contrastText: white
    },
    primary1Color: cyan500,
    primary2Color: cyan700,
    primary3Color: grey400,
    accent1Color: green400,
    accent2Color: grey100,
    accent3Color: grey500,
    textColor: darkBlack,
    secondaryTextColor: alpha(darkBlack, 0.54),
    alternateTextColor: white,
    canvasColor: white,
    borderColor: grey300,
    disabledColor: alpha(darkBlack, 0.3),
    pickerHeaderColor: cyan500,
    clockCircleColor: alpha(darkBlack, 0.07),
    shadowColor: fullBlack,
    common: {
      grey: grey500,
      grey200,
    }
  },
  overrides: {
    MuiTableRow: {
      root: {
        '&$selected': {
          backgroundColor: grey50,
          '&:hover': {
            backgroundColor: grey300,
          },
        },
      }
    },
    MuiTableCell: {
      root: {
        fontSize: '12px',
        lineHeight: '14px',
      },
      sizeSmall: {
        height: 33,
        padding: '2px 15px',
      }
    },
    MuiIconButton: {
      root: {
        padding: '2px',
        width: '32px',
        height: '32px',
      }
    },
    MuiSvgIcon: {
      root: {
        width: 18,
        height: 18,
      }
    },
    MuiListItem: {
      root: {
        paddingTop: '4px',
        paddingBottom: '4px',
        height: '32px',
        minHeight: '32px',
      }
    },
    MuiMenuItem: {
      root: {
        height: '32px',
        minHeight: '32px',
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32
      }
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: 0,
      }
    },
    MuiAppBar: {
      root: {
        boxShadow: 'none',
      },
      colorPrimary: {
        color: 'inherit',
        backgroundColor: 'inherit',
      },
    },
    MuiToolbar: {
      root: {
        color: 'inherit',
        backgroundColor: 'inherit',
      }
    },
    MuiDialogContent: {
      root: {
        borderTop: '1px solid #ffb821',
        borderBottom: '1px solid #eee',
        padding: '16px',
      }
    },
    MuiTooltip: {
      tooltip: {
        fontSize: 12
      }
    },
    MuiTabs: {
      root: {
        borderBottom: 'none',
        minHeight: '40px',
      },
      indicator: {
        display: 'none',
      }
    },
    MuiTab: {
      root: {
        borderRadius: '8px 8px 0 0',
        backgroundColor: grey200,
        minHeight: '36px',
        marginTop: '4px',
        '&:hover': {
          backgroundColor: grey200,
        },
        '&$selected': {
          minHeight: '40px',
          marginTop: 0,
        },
      },
      labelIcon: {
        minHeight: '36px',
      },
      textColorInherit: {
        opacity: 0.4,
      },
    },
    MuiSelect: {
      icon: {
        top: 'auto',
      }
    }
  },
};
