import { handleDownload, handleResponse, fetchIntercept } from '../helpers';

function list() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/agent/list', requestOptions).then(handleResponse);
}

function download(type) {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept(`/api/agent/download/${type}`, requestOptions).then(handleDownload);
}

function getVersions() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/agent/version', requestOptions).then(handleResponse);
}

export const agentService = {
  list,
  download,
  getVersions,
};
