import { fetchIntercept, handleResponse } from '../helpers';

function list() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/ldap/config', requestOptions).then(handleResponse);
}

function set(config = {}) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({...config})
  };

  return fetchIntercept('/api/ldap/config', requestOptions).then(handleResponse);
}

function testConnection(config) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({...config})
  };

  return fetchIntercept('/api/ldap/test', requestOptions).then(handleResponse);
}

export const ldapService = {
  list,
  set,
  testConnection
};
