import { fetchIntercept, handleResponse } from '../helpers';

function list(id) {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept(`/api/distribution-boundary/${id}`, requestOptions).then(handleResponse);
}

function add(id, ipStart, ipEnd) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ ip_start: ipStart, ip_end: ipEnd }),
  };

  return fetchIntercept(`/api/distribution-boundary/${id}`, requestOptions).then(handleResponse);
}

function remove(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id }),
  };

  return fetchIntercept('/api/distribution-boundary', requestOptions).then(handleResponse);
}

export const distributionBoundaryService = {
  list,
  add,
  remove,
};
