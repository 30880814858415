import {
  SYSTEM_INFO_REQUEST,
  SYSTEM_INFO_SUCCESS,
  SYSTEM_INFO_FAILURE,
} from '../constants/ActionTypes';

const SYSTEM_INFO_INITIAL_STATE = {
  version: '',
  updated: '',
};

const system = (state = SYSTEM_INFO_INITIAL_STATE, action = null) => {
  switch (action.type) {
    case SYSTEM_INFO_REQUEST: {
      return state;
    }
    case SYSTEM_INFO_SUCCESS:
      return action.data;
    case SYSTEM_INFO_FAILURE:
      return state;
    default:
      return state;
  }
};

export default system;
