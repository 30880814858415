import { handleResponse, handleDownload, fetchIntercept } from '../helpers';

function createReport(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetchIntercept('/api/report', requestOptions).then(handleResponse);
}

function reports() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/report/list', requestOptions).then(handleResponse);
}

function deleteReports(reportIds) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ reportIds })
  };

  return fetchIntercept('/api/report/list', requestOptions).then(handleResponse);
}

function downloadCsv(id) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ reportId: id, date: new Date() })
  };

  fetchIntercept('/api/report/csv', requestOptions).then(handleDownload);
}

function downloadPdf(id) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ reportId: id, date: new Date() })
  };

  fetchIntercept('/api/report/pdf', requestOptions).then(handleDownload);
}

function downloadReportArchive(reportId, fileName) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ fileName })
  };

  fetchIntercept(`/api/report/archive/download/${reportId}`, requestOptions).then(handleDownload);
}

function reportArchives(id) {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept(`/api/report/archives/${id}`, requestOptions).then(handleResponse);
}

function deleteReportArchive(reportId, reports) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ reports })
  };

  return fetchIntercept(`/api/report/archives/${reportId}`, requestOptions).then(handleResponse);
}

function query(qs) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ qs })
  };

  return fetchIntercept('/api/report/query', requestOptions).then(handleDownload);
}

function updateSchedule(reportId, scheduleReportId, schedule) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ reportId, scheduleReportId, schedule })
  };

  return fetchIntercept('/api/report/schedule', requestOptions).then(handleResponse);
}

export const reportService = {
  createReport,
  reports,
  reportArchives,
  deleteReports,
  downloadCsv,
  downloadPdf,
  deleteReportArchive,
  downloadReportArchive,
  query,
  updateSchedule,
};
