import { handleResponse, fetchIntercept } from '../helpers';

function login(username, password, isLdap = false) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ username, password })
  };

  return fetch(`/auth/signin${isLdap ? '/ldap' : ''}`, requestOptions)
    .then((res) => res.json())
    .then((dataJson) => {
      if (!dataJson.error) {
        const user = dataJson.data;
        if (user && user.token) {
          localStorage.setItem('user', JSON.stringify(user));
        }

        return user;
      }
      return Promise.reject(dataJson.message);
    });
}

function verify(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetchIntercept('/auth/2fa/verify', requestOptions).then(handleResponse);
}

function resend(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetchIntercept('/auth/2fa/resend', requestOptions).then(handleResponse);
}

function logout() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/user/logout', requestOptions)
    .then((res) => {
      localStorage.removeItem('user');

      return res.json();
    })
    .catch((e) => {
      localStorage.removeItem('user');

      return Promise.reject(e);
    });
}

function getToken() {
  const user = localStorage.getItem('user');
  return user ? JSON.parse(user).token : null;
}

function getRole() {
  const user = localStorage.getItem('user');
  return user ? JSON.parse(user).role : null;
}

function getUsername() {
  const user = localStorage.getItem('user');
  return user ? JSON.parse(user).username : '';
}

function getUserEmail() {
  const user = localStorage.getItem('user');
  return user ? JSON.parse(user).email : '';
}

function getFeatures() {
  const user = localStorage.getItem('user');

  if (!user) return null;

  const userData = JSON.parse(user);
  const {features} = userData;

  return features || [];
}

function register(user) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      username: user.username,
      email: user.email,
      password: user.password
    })
  };

  return fetch('/auth/signup', requestOptions)
    .then((res) => res.json())
    .then((dataJson) => {
      if (!dataJson.error) {
        return dataJson.data;
      }
      return Promise.reject(dataJson.message);
    });
}

function forgetPassword(email) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({email})
  };

  return fetch('/auth/forgot-password', requestOptions)
    .then((res) => res.json())
    .then((dataJson) => {
      if (!dataJson.error) {
        return dataJson.message;
      }
      return Promise.reject(dataJson.message);
    });
}

function resetPassword(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetch('/auth/reset-password', requestOptions)
    .then((res) => res.json())
    .then((dataJson) => {
      if (!dataJson.error) {
        return dataJson.message;
      }
      return Promise.reject(dataJson.message);
    });
}

function list() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/user/list', requestOptions).then(handleResponse);
}

function create(user) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      username: user.username,
      email: user.email,
      password: user.password,
      role: user.role,
      license: user.license,
    })
  };

  return fetchIntercept('/api/user/create', requestOptions).then(handleResponse);
}

/**
 * userData = {id: userId} || {username: userName} || {email: userEmail}
 */
function user(userData) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(userData)
  };

  return fetchIntercept('/api/user', requestOptions).then(handleResponse);
}

function changePassword(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetchIntercept('/api/user/change-password', requestOptions).then(handleResponse);
}

function deleteUsers(usernames) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({usernames})
  };

  return fetchIntercept('/api/user/delete', requestOptions).then(handleResponse);
}

function confirmAccount(key) {
  const requestOptions = {
    method: 'GET'
  };

  return fetch(`/auth/confirm/${key}`, requestOptions)
    .then((res) => res.json())
    .then((dataJson) => {
      if (!dataJson.error) {
        return dataJson.data;
      }
      throw new Error(dataJson.message);
    })
    .catch((e) => Promise.reject(e));
}

function setPassword(key, password) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      key,
      password
    })
  };

  return fetch('/auth/password', requestOptions)
    .then((res) => res.json())
    .then((dataJson) => {
      if (!dataJson.error) {
        return dataJson.data;
      }
      throw new Error(dataJson.message);
    })
    .catch((e) => Promise.reject(e));
}

export const userService = {
  login,
  verify,
  resend,
  logout,
  register,
  forgetPassword,
  resetPassword,
  getToken,
  getRole,
  list,
  user,
  create,
  deleteUsers,
  confirmAccount,
  setPassword,
  getUsername,
  getUserEmail,
  getFeatures,
  changePassword,
};
