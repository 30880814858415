import { fetchIntercept, handleResponse } from '../helpers';

function status() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/2fa/status', requestOptions).then(handleResponse);
}

function enable() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/2fa', requestOptions).then(handleResponse);
}

function disable() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/2fa/disable', requestOptions).then(handleResponse);
}

function verify(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };

  return fetchIntercept('/api/2fa/verify', requestOptions).then(handleResponse);
}

function resend(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };

  return fetchIntercept('/api/2fa/resend', requestOptions).then(handleResponse);
}

export const twoFAService = {
  status,
  enable,
  disable,
  verify,
  resend,
};
