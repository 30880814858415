import {
  USERS_LOGIN_REQUEST,
  USERS_LOGIN_SUCCESS,
  USERS_LOGIN_FAILURE,
  USERS_LOGOUT_REQUEST,
  USERS_LOGOUT_SUCCESS,
  USERS_LOGOUT_FAILURE,
} from '../constants/ActionTypes';

const user = JSON.parse(localStorage.getItem('user'));

const INITIAL_STATE = user
  ? {
    user,
    loggedIn: true,
    error: null,
  }
  : {
    loggedIn: false,
    error: null,
  };

const auth = (state = INITIAL_STATE, action = null) => {
  switch (action.type) {
    case USERS_LOGIN_REQUEST:
      return {
        ...state,
        loggedIn: true,
        user: action.payload,
        error: null
      };
    case USERS_LOGIN_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        user: action.payload,
      };
    case USERS_LOGIN_FAILURE:
      return {
        ...state,
        error: action.payload,
        data: null
      };
    case USERS_LOGOUT_REQUEST:
      return {
        error: null,
      };
    case USERS_LOGOUT_SUCCESS:
      return {
        user: null,
        loggedIn: false,
        error: null,
      };
    case USERS_LOGOUT_FAILURE:
      return {
        error: action.payload,
      };
    default:
      return state;
  }
};

export default auth;
