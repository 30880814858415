import * as types from '../constants/ActionTypes';
import {scanService} from '../services';

function status(ids = [], level = '') {

  function request(data) { return { type: types.SCCM_SCAN_STATUS_REQUEST, data }; }
  function success(data) { return { type: types.SCCM_SCAN_STATUS_SUCCESS, data }; }
  function failure(error) { return { type: types.SCCM_SCAN_STATUS_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request({ids, level}));

    let scanStatusInter = 0;

    const startCheckScanStatus = () => {
      scanService.scanStatus()
        .then((status) => {
          dispatch(success(status.data));
          if (!status.data.progress.isScanning) {
            clearInterval(scanStatusInter);
          }
        })
        .catch((e) => {
          clearInterval(scanStatusInter);
          dispatch(failure(e.message));
        });
    };
    startCheckScanStatus();
    scanStatusInter = setInterval(startCheckScanStatus, 3000);
  };
}

export const scanActions = {
  status,
};
