import {
  SCCM_TOGGLE_STATUS,
  SCCM_PLUGIN_VERSION,
  SCCM_LAST_SYNC_REQUEST,
  SCCM_LAST_SYNC_SUCCESS,
  SCCM_LAST_SYNC_FAILURE,
} from '../constants/ActionTypes';

const INITIAL_STATUS_STATE = {
  isOnline: false,
  version: '',
};

const plugin = (state = INITIAL_STATUS_STATE, action = null) => {
  switch (action.type) {
    case SCCM_TOGGLE_STATUS:
      return {
        ...state,
        isOnline: action.isOnline
      };
    case SCCM_PLUGIN_VERSION:
      return {
        ...state,
        version: action.version
      };
    default:
      return state;
  }
};

const INITIAL_SYNC_STATE = {
  last: null,
  loading: false,
  error: '',
};

const sync = (state = INITIAL_SYNC_STATE, action = null) => {
  switch (action.type) {
    case SCCM_LAST_SYNC_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SCCM_LAST_SYNC_SUCCESS:
      return {
        ...state,
        last: action.data,
      };
    case SCCM_LAST_SYNC_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
};

export {
  plugin,
  sync
};
