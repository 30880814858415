import {
  SCCM_SCAN_STATUS_REQUEST,
  SCCM_SCAN_STATUS_SUCCESS,
  SCCM_SCAN_STATUS_FAILURE,
} from '../constants/ActionTypes';

const INITIAL_STATE = {
  collections: [],
  level: '',
  progress: {
    isScanning: false,
    current: 0,
    total: 0,
  }
};

const status = (state = INITIAL_STATE, action = null) => {
  switch (action.type) {
    case SCCM_SCAN_STATUS_REQUEST: {
      const {ids, level} = action.data;
      return {
        collections: ids,
        level,
        progress: {
          isScanning: true,
          current: 0,
          total: 0,
        }
      };
    }
    case SCCM_SCAN_STATUS_SUCCESS:
      return action.data;
    case SCCM_SCAN_STATUS_FAILURE:
      return action.error;
    default:
      return state;
  }
};

export default status;
