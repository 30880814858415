export const formatFileSize = (size) => {
  const fileSize = Number.isNaN(size) ? Number.parseInt(size, 10) : size;
  let result = '';

  if (fileSize >= 1073741824) {
    result = `${Math.round((size / 1073741824) * 1000) / 1000} GB`;
  } else if (fileSize >= 1048576) {
    result = `${Math.round((size / 1048576) * 1000) / 1000} MB`;
  } else if (fileSize >= 1024) {
    result = `${Math.round((size / 1024) * 1000) / 1000} KB`;
  } else if (fileSize > 0) {
    result = `${size} B`;
  } else if (fileSize === 0) {
    result = 0;
  }

  return result;
};

export const formatSnapshotSize = (size) => {
  const s = Number.isNaN(size) ? Number.parseInt(size, 10) : size;
  let result = 0;
  let extension = 'MB';

  if (s >= 1099511627776) {
    result = parseFloat((Math.round((s / 1099511627776) * 1000) / 1000).toFixed(2));
    extension = 'TB';
  } else if (s >= 1073741824) {
    result = parseFloat((Math.round((s / 1073741824) * 1000) / 1000).toFixed(2));
    extension = 'GB';
  } else if (s >= 1048576) {
    result = parseFloat((Math.round((s / 1048576) * 1000) / 1000).toFixed(2));
  } else if (s >= 1024) {
    result = parseFloat((Math.round((s / 1024) * 1000) / 1000).toFixed(2));
    extension = 'KB';
  } else if (s > 0) {
    result = parseFloat(s.toFixed(2));
    extension = 'B';
  }

  return {
    size: result,
    extension,
  };
};

export const getFileExtension = (filename) => ((/[.]/.exec(filename)) ? /[^.]+$/.exec(filename)[0] : undefined);
