import {
  ALERT_SUCCESS,
  ALERT_ERROR,
  ALERT_CLEAR,
} from '../constants/ActionTypes';

const INITIAL_STATE = {
  alertType: '',
  alertMessage: '',
};

const alert = (state = INITIAL_STATE, action = null) => {
  switch (action.type) {
    case ALERT_SUCCESS:
      return {
        ...state,
        alertType: 'success',
        alertMessage: action.alertMessage
      };
    case ALERT_ERROR:
      return {
        ...state,
        alertType: 'error',
        alertMessage: action.alertMessage
      };
    case ALERT_CLEAR:
      return {
        ...state,
        alertType: '',
        alertMessage: ''
      };
    default:
      return state;
  }
};

export default alert;
