import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { amber, green } from '@material-ui/core/colors';
import { IconButton, SnackbarContent, withStyles } from '@material-ui/core';
import {
  CheckCircle as CheckCircleIcon,
  Close as CloseIcon,
  Error as ErrorIcon,
  Info as InfoIcon,
  Warning as WarningIcon
} from '@material-ui/icons';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const stylesSnackbarContent = (theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
    color: amber,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

class SnackbarContentWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      classes, className, message, onClose, variant, ...other
    } = this.props;
    const Icon = variant && variantIcon[variant] ? variantIcon[variant] : null;

    return (
      <SnackbarContent
        className={classNames(classes[variant], className)}
        aria-describedby="client-snackbar"
        message={(
          <span id="client-snackbar" className={classes.message}>
            {Icon && <Icon className={classNames(classes.icon, classes.iconVariant)} color="action" />}
            {message}
          </span>
        )}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.close}
            onClick={onClose}
          >
            <CloseIcon className={classes.icon} color="action" />
          </IconButton>,
        ]}
        {...other}
      />
    );
  }

}

SnackbarContentWrapper.defaultProps = {
  className: '',
  message: null,
  onClose: () => {},
  variant: '',
};

SnackbarContentWrapper.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.string,
};

export default withStyles(stylesSnackbarContent)(SnackbarContentWrapper);
