import React from 'react';
import { connect } from 'react-redux';
import { Snackbar } from '@material-ui/core';
import { alertActions } from 'actions';
import SnackbarContentWrapper from './components/SnackbarContentWrapper';

class SystemAlerts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleClose = (event, reason) => {
    const {dispatch} = this.props;
    if (reason === 'clickaway') {
      return;
    }

    dispatch(alertActions.clear());
  };

  render() {
    const { alert } = this.props;

    const variant = alert.alertType;
    const message = alert.alertMessage;
    const open = !!(variant && message);

    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={this.handleClose}
      >
        <SnackbarContentWrapper
          variant={variant}
          message={message}
          onClose={this.handleClose}
        />
      </Snackbar>
    );
  }
}

const mapStateToProps = (state) => ({alert: state.alert});

export default connect(mapStateToProps)(SystemAlerts);
