import { handleResponse, fetchIntercept } from '../helpers';

function products() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/product/list', requestOptions).then(handleResponse);
}

function productsInstalled() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/product/installed/list', requestOptions).then(handleResponse);
}

function mostVulnarable() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/product/most-vulnarable/list', requestOptions).then(handleResponse);
}

function productsPAT() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/product/PAT/list', requestOptions).then(handleResponse);
}

function productsINS() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/product/INS/list', requestOptions).then(handleResponse);
}

function productsEOL() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/product/EOL/list', requestOptions).then(handleResponse);
}

function productsDSC() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/product/DSC/list', requestOptions).then(handleResponse);
}

function productsMicrosoft() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/product/microsoft/list', requestOptions).then(handleResponse);
}

function productsMac() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/product/mac/list', requestOptions).then(handleResponse);
}

function product(productId, installed = 0) {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept(`/api/product/${productId}/?installed=${installed}`, requestOptions).then(handleResponse);
}

function uninstallScript(fileName) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ file_name: fileName })
  };

  return fetchIntercept('/api/product/uninstall-script', requestOptions).then(handleResponse);
}

function installScript(fileName) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ file_name: fileName })
  };

  return fetchIntercept('/api/product/install-script', requestOptions).then(handleResponse);
}

function productsStatus() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/product/status', requestOptions).then(handleResponse);
}

function noInstallation() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/product/no-installation/list', requestOptions).then(handleResponse);
}

function requestInstallation(fileNames) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ file_names: fileNames })
  };

  return fetchIntercept('/api/product/request-installation', requestOptions).then(handleResponse);
}

function uniqueProducts() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/product/unique/list', requestOptions).then(handleResponse);
}

function zombieProducts() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/product/zombie', requestOptions).then(handleResponse);
}

function vendorProducts() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/product/vendor', requestOptions).then(handleResponse);
}

function options(vendor, product) {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept(`/api/product/options/?vendor=${vendor}&product=${product}`, requestOptions).then(handleResponse);
}

function impact() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/product/impact', requestOptions).then(handleResponse);
}

export const productService = {
  mostVulnarable,
  productsPAT,
  productsEOL,
  productsINS,
  productsDSC,
  productsMicrosoft,
  productsMac,
  product,
  products,
  productsInstalled,
  uninstallScript,
  installScript,
  productsStatus,
  noInstallation,
  requestInstallation,
  uniqueProducts,
  zombieProducts,
  vendorProducts,
  options,
  impact,
};
