export const isFeatureActive = (name = '') => {
  const user = localStorage.getItem('user');

  if (!user) return null;

  const userData = JSON.parse(user);
  const { features = [] } = userData;

  return features.indexOf(name.toUpperCase()) !== -1;
};
