import { fetchIntercept, handleResponse } from '../helpers';

function list() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/unauthorized', requestOptions).then(handleResponse);
}

function create(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetchIntercept('/api/unauthorized', requestOptions).then(handleResponse);
}

function remove(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ids: data})
  };

  return fetchIntercept('/api/unauthorized/remove', requestOptions).then(handleResponse);
}

export const unauthorizedService = {
  list,
  create,
  remove,
};
