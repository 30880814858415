const normalize = (str) => (str.toString().padStart(2, '0'));

export const shortDateTime = (str) => {
  if (!str) return '';

  const dateTime = new Date(str);

  const day = normalize(dateTime.getDate());
  const month = normalize(dateTime.getMonth() + 1);
  const year = dateTime.getFullYear();
  const hour = normalize(dateTime.getHours());
  const minute = normalize(dateTime.getMinutes());

  return `${year}/${month}/${day}, ${hour}:${minute}`;
};

export const currentDate = (offsetMonth = 0) => {
  const dateTime = new Date();
  dateTime.setMonth(dateTime.getMonth() + offsetMonth);

  const day = normalize(dateTime.getDate());
  const month = normalize(dateTime.getMonth() + 1);
  const year = dateTime.getFullYear();

  return `${year}-${month}-${day}`;
};

export const getTime = (str = '') => {
  const dateTime = str ? new Date(str) : new Date();

  const hour = normalize(dateTime.getHours());
  const minute = normalize(dateTime.getMinutes());

  return `${hour}:${minute}`;
};

export const getDate = (str = '') => {
  const dateTime = str ? new Date(str) : new Date();

  const day = normalize(dateTime.getDate());
  const month = normalize(dateTime.getMonth() + 1);
  const year = dateTime.getFullYear();

  return `${year}-${month}-${day}`;
};

export const currentTimestamp = () => {
  const dateTime = new Date();

  const day = normalize(dateTime.getDate());
  const month = normalize(dateTime.getMonth() + 1);
  const year = dateTime.getFullYear();
  const hour = normalize(dateTime.getHours());
  const minute = normalize(dateTime.getMinutes());
  const second = normalize(dateTime.getSeconds());

  return `${year}${month}${day}-${hour}${minute}${second}`;
};

export const dateOnly = (str) => {
  if (!str) return '';

  const dateTime = new Date(str);

  const day = normalize(dateTime.getDate());
  const month = normalize(dateTime.getMonth() + 1);

  return `${day}/${month}`;
};

export const getDateTimeUtc = (str) => {
  const dateTime = str ? new Date(str) : new Date();

  const day = normalize(dateTime.getDate());
  const month = normalize(dateTime.getMonth() + 1);
  const year = dateTime.getFullYear();
  const hour = normalize(dateTime.getHours());
  const minute = normalize(dateTime.getMinutes());

  return `${year}-${month}-${day}T${hour}:${minute}`;
};

export const mToYDHM = (newMinutes) => {
  const MINS_PER_YEAR = 365 * 24 * 60;
  const MINS_PER_DAY = 24 * 60;
  const MINS_PER_HOUR = 60;

  let minutes = newMinutes;

  const years = Math.floor(minutes / MINS_PER_YEAR);
  minutes -= years * MINS_PER_YEAR;

  const days = Math.floor(minutes / MINS_PER_DAY);
  minutes -= days * MINS_PER_DAY;

  const hours = Math.floor(minutes / MINS_PER_HOUR);
  minutes -= hours * MINS_PER_HOUR;

  return `${years}y ${days}d ${normalize(hours)}h ${normalize(minutes)}m`;
};
