import { fetchIntercept, handleResponse } from '../helpers';

function list() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/user/roles', requestOptions).then(handleResponse);
}

export const roleService = {
  list,
};
