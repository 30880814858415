import * as types from '../constants/ActionTypes';
import {sccmService} from '../services';

export function sccmToggleStatus(isOnline) {
  return { type: types.SCCM_TOGGLE_STATUS, isOnline };
}

export function lastSync() {
  function request() { return { type: types.SCCM_LAST_SYNC_REQUEST }; }
  function success(data) { return { type: types.SCCM_LAST_SYNC_SUCCESS, data }; }
  function failure(error) { return { type: types.SCCM_LAST_SYNC_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());

    sccmService.lastSync()
      .then((syncData) => {
        if (syncData.error) throw new Error(syncData.message);
        dispatch(success(syncData.data));
      })
      .catch((e) => {
        dispatch(failure(e.message));
      });
  };
}

export function sccmPluginVersion(version) {
  return { type: types.SCCM_PLUGIN_VERSION, version };
}
