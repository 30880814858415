import {history} from './history';

export const downloadFile = (blob, filename, type) => {
  const newBlob = new Blob([blob], {type});

  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob, filename);
    return;
  }

  const data = window.URL.createObjectURL(newBlob);

  const link = document.createElement('a');
  link.href = data;
  link.download = filename;
  document.body.appendChild(link);
  link.click();

  setTimeout(() => {
    document.body.removeChild(link);
    window.URL.revokeObjectURL(data);
  }, 100);
};

export const handleResponse = (res) => {
  if (res.status === 401) {
    history.push('/logout');
    throw new Error('Unauthorized');
  }

  return res.json();
};

export function handleError(e) {
  console.log(e.message);
  return Promise.reject(e);
}

export function handleDownload(res) {
  if (res.status === 401) {
    history.push('/logout');
    throw new Error('Unauthorized');
  }

  const contentType = res.headers.get('content-type');
  const contentDisposition = res.headers.get('content-disposition');

  if (!contentType || !contentDisposition) throw new Error('Something went wrong!');

  const fileName = contentDisposition.split('=')[1].replace(/"/g, '');

  if (contentType.indexOf('application/json') !== -1) {
    res.json().then((dataJson) => {
      throw new Error(dataJson.error ? dataJson.message : 'Something went wrong!');
    });
    return null;
  }

  if (contentType.indexOf('text/csv') !== -1) {
    return res.blob().then((blob) => downloadFile(blob, fileName, 'text/csv'));
  }

  if (contentType.indexOf('application/pdf') !== -1) {
    return res.blob().then((blob) => downloadFile(blob, fileName, 'application/pdf'));
  }

  if (contentType.indexOf('application/octet-stream') !== -1) {
    return res.blob().then((blob) => downloadFile(blob, fileName, 'application/octet-stream'));
  }

  return null;
}

export function authHeader() {
  const user = JSON.parse(localStorage.getItem('user'));

  return user && user.token ? { Authorization: user.token } : {};
}
