import { fetchIntercept, handleResponse } from '../helpers';

function list(vendor, product) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json'},
    body: JSON.stringify({vendor, product})
  };

  return fetchIntercept('/api/deployment-template/list', requestOptions).then(handleResponse);
}

function save(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json'},
    body: JSON.stringify(data)
  };

  return fetchIntercept('/api/deployment-template/save', requestOptions).then(handleResponse);
}

function remove(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json'},
    body: JSON.stringify(data)
  };

  return fetchIntercept('/api/deployment-template/delete', requestOptions).then(handleResponse);
}

export const deploymentTemplateService = {
  list,
  save,
  remove,
};
