export const getLevel = (score) => {
  if (score >= 0 && score <= 2) {
    return 'low';
  }
  if (score > 2 && score <= 4) {
    return 'medium';
  }
  if (score > 4 && score <= 6) {
    return 'high';
  }
  if (score > 6 && score <= 8) {
    return 'very_high';
  }
  if (score > 8 && score <= 10) {
    return 'critical';
  }

  return 'unknown';
};
