import { authHeader } from './request';

const delay = (milliseconds) => new Promise((resolve) => {
  setTimeout(() => resolve(), milliseconds);
});

export const fetchIntercept = async (...args) => {
  const [resource, config] = args;

  const auth = authHeader();

  if (config.headers) {
    config.headers = { ...config.headers, ...auth };
  } else {
    config.headers = auth;
  }

  const res = await fetch(resource, config);

  if (res.status === 401) {
    const user = localStorage.getItem('user');

    if (!user) {
      return res;
    }

    const isRefreshing = localStorage.getItem('refresh');

    if (isRefreshing) {
      await delay(1000);

      return fetchIntercept(resource, config);
    }

    localStorage.setItem('refresh', '1');

    const token = await fetch('/auth/refresh', { method: 'GET', headers: auth, })
      .then((res) => res.json())
      .then(({ error, message, data }) => {
        if (error) {
          throw new Error(message);
        }

        return data;
      })
      .catch((err) => console.log(err.message));

    localStorage.removeItem('refresh');

    if (!token) {
      return res;
    }

    const userObj = JSON.parse(user);

    userObj.token = token;

    localStorage.setItem('user', JSON.stringify(userObj));

    return fetchIntercept(resource, config);
  }

  return res;
};
