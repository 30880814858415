import { fetchIntercept, handleResponse } from '../helpers';

function read() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/config/user', requestOptions).then(handleResponse);
}

function save(config) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(config)
  };

  return fetchIntercept('/api/config/user', requestOptions).then(handleResponse);
}

export const userConfigurationService = {
  read,
  save
};
