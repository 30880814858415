import React, { Component, Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { Route, Redirect } from 'react-router-dom';
import PrivateRoute from '../components/PrivateRoute';
import OnlyPublicRoute from '../components/OnlyPublicRoute';
import SystemAlerts from '../components/SystemAlerts';

// = styles =
// 3rd
import 'styles/bootstrap.scss';
// custom
import 'styles/layout.scss';
import 'styles/theme.scss';
import 'styles/ui.scss';
import 'styles/app.scss';

import lightTheme from './themes/lightTheme';

const MainApp = lazy(() => import('routes/app/'));
const Page404 = lazy(() => import('routes/404/'));
const Page500 = lazy(() => import('routes/500/'));
const PageConfirmAccount = lazy(() => import('routes/confirm-account/'));
const PageConfirmEmail = lazy(() => import('routes/confirm-email/'));
const PageForgotPassword = lazy(() => import('routes/forgot-password/'));
const PageResetPassword = lazy(() => import('routes/reset-password/'));
const PageLockScreen = lazy(() => import('routes/lock-screen/'));
const PageLogin = lazy(() => import('routes/login/'));
const PageLogout = lazy(() => import('routes/logout/'));
const PageSignUp = lazy(() => import('routes/registration/'));

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  render() {
    const {
      match, location, layoutBoxed, navCollapsed, navBehind, fixedHeader, sidebarWidth, theme
    } = this.props;
    const themeV1 = createTheme(lightTheme);

    const isRoot = location.pathname === '/';
    if (isRoot) {
      return (<Redirect to="/app/dashboard" />);
    }

    return (
      <MuiThemeProvider theme={themeV1}>
        <div id="app-inner">
          <div className="preloaderbar hide"><span className="bar" /></div>
          <SystemAlerts />
          <div
            className={classNames('app-main full-height', {
              'fixed-header': fixedHeader,
              'nav-collapsed': navCollapsed,
              'nav-behind': navBehind,
              'layout-boxed': layoutBoxed,
              'theme-gray': theme === 'gray',
              'theme-dark': theme === 'dark',
              'sidebar-sm': sidebarWidth === 'small',
              'sidebar-lg': sidebarWidth === 'large'
            })}
          >
            <Suspense fallback={<div> </div>}>
              <PrivateRoute path={`${match.url}app`} component={MainApp} />
              <Route exact path="/404" component={Page404} />
              <Route exact path="/500" component={Page500} />
              <OnlyPublicRoute exact path="/reset-password/:email/:key" component={PageResetPassword} />
              <OnlyPublicRoute exact path="/confirm-account/:key" component={PageConfirmAccount} />
              <Route exact path="/confirm-email" component={PageConfirmEmail} />
              <Route exact path="/forgot-password" component={PageForgotPassword} />
              <Route exact path="/lock-screen" component={PageLockScreen} />
              <OnlyPublicRoute exact path="/login/:username?" component={PageLogin} />
              <Route exact path="/logout" component={PageLogout} />
              <OnlyPublicRoute exact path="/sign-up" component={PageSignUp} />
            </Suspense>
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  layoutBoxed: state.settings.layoutBoxed,
  navCollapsed: state.settings.navCollapsed,
  navBehind: state.settings.navBehind,
  fixedHeader: state.settings.fixedHeader,
  sidebarWidth: state.settings.sidebarWidth,
  theme: state.settings.theme,
});

export default connect(mapStateToProps)(App);
