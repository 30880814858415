import { fetchIntercept, handleResponse } from '../helpers';

function distributions() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/distribution', requestOptions).then(handleResponse);
}

function toggleDistribution(id, enabled) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ enabled }),
  };

  return fetchIntercept(`/api/distribution/${id}`, requestOptions).then(handleResponse);
}

function remove(ids) {
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ ids }),
  };

  return fetchIntercept('/api/distribution/', requestOptions).then(handleResponse);
}

function status(id) {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept(`/api/distribution/status/${id}`, requestOptions).then(handleResponse);
}

function addDistributionToPackage(id, ids) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ ids }),
  };

  return fetchIntercept(`/api/distribution/add/${id}`, requestOptions).then(handleResponse);
}

function redistribute(id, ids) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ ids }),
  };

  return fetchIntercept(`/api/distribution/redistribute/${id}`, requestOptions).then(handleResponse);
}

function deleteDistributionFromPackage(id, ids) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ ids }),
  };

  return fetchIntercept(`/api/distribution/delete/${id}`, requestOptions).then(handleResponse);
}

export const distributionService = {
  distributions,
  toggleDistribution,
  remove,
  status,
  redistribute,
  addDistributionToPackage,
  deleteDistributionFromPackage,
};
