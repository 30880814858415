import { fetchIntercept, handleResponse } from '../helpers';

function groups(isClient = 0) {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept(`/api/group?is_client=${isClient}`, requestOptions).then(handleResponse);
}

function members(groupId, isClient = 0) {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept(`/api/group/${groupId}?is_client=${isClient}`, requestOptions).then(handleResponse);
}

function scan(groupId) {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept(`/api/group/scan/${groupId}`, requestOptions).then(handleResponse);
}

function scanSave(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetchIntercept(`/api/group/scan/${data.groupId}`, requestOptions).then(handleResponse);
}

function missingHosts(groupId, isClient = 0) {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept(`/api/group/missed/${groupId}?is_client=${isClient}`, requestOptions).then(handleResponse);
}

function createGroup(groupName) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ groupName })
  };

  return fetchIntercept('/api/group', requestOptions).then(handleResponse);
}

function deleteGroups(groups) {
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ groups })
  };

  return fetchIntercept('/api/group', requestOptions).then(handleResponse);
}

function addHosts(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ hosts: data.hosts })
  };

  return fetchIntercept(`/api/group/host/${data.groupId}`, requestOptions).then(handleResponse);
}

function removeHosts(data) {
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ hosts: data.hosts })
  };

  return fetchIntercept(`/api/group/host/${data.groupId}`, requestOptions).then(handleResponse);
}

export const groupsService = {
  groups,
  members,
  scan,
  scanSave,
  createGroup,
  deleteGroups,
  addHosts,
  removeHosts,
  missingHosts,
};
