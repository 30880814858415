import { fetchIntercept, handleResponse } from '../helpers';

function details() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/license/details', requestOptions).then(handleResponse);
}

function missing() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/license/missing', requestOptions).then(handleResponse);
}

function available() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/license/available', requestOptions).then(handleResponse);
}

function updateLicense(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetchIntercept('/api/license/update', requestOptions).then(handleResponse);
}

export const licenseService = {
  details,
  missing,
  available,
  updateLicense,
};
